import styles from "./styles.module.css"; // Adjust the path as needed
import { useGetResume } from "../../hooks/use-get-resume"; // Adjust the path as needed

const ResumeDisplay = () => {
  const { data, loading, error } = useGetResume();

  if (loading) return <></>;
  if (error) return <></>;

  const categoryOrder = [
    "Résidences",
    "Bourses",
    "Expositions personnelles / En duo",
    "Expositions collectives (sélection)",
    "Collections",
    "Publications",
    "Formation",
  ];

  const groupedData = data.reduce((acc, item) => {
    const category = item.category.title;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  Object.keys(groupedData).forEach((category) => {
    groupedData[category].sort((a, b) => new Date(b.date) - new Date(a.date));
  });

  return (
    <div className={styles.container}>
      {categoryOrder.map((category) => {
        const items = groupedData[category];
        if (!items) return null;
        return (
          <div className={styles.category} key={category}>
            <h2>{category}</h2>
            {items.map((item, index, array) => (
              <div className={styles.title} key={item.title}>
                <div>
                  {index === 0 || array[index - 1].date !== item.date
                    ? item.date
                    : ""}
                </div>
                <div>{item.title}</div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default ResumeDisplay;

import { useGetPaintings } from "../../hooks/use-get-paintings";
import Work from "../../components/work";

function Paintings() {
  const { data, loading, error } = useGetPaintings();

  if (loading || !data) {
    return <></>;
  }
  if (error) {
    return <div>Une erreur est survenue</div>;
  }

  return <Work data={data} linkLabel="Sélection de peintures en pdf" />;
}

export default Paintings;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Layout from "./components/layout";
import Paintings from "./pages/paintings";
import Resume from "./pages/resume";
import Critics from "./pages/critics";
import Contact from "./pages/contact";


import ObjectBooks from "./pages/object-books";
import PoetryBooks from "./pages/poetry-books";

function App() {

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/peintures" element={<Paintings />} />
          <Route path="/CV" element={<Resume />} />
          <Route path="/livre-objet" element={<ObjectBooks />} />
          <Route path="/poesie" element={<PoetryBooks />} />
          <Route path="/textes" element={<Critics />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;

import { useState, useEffect } from "react";
import { fetchHomepageData } from "../api/home-page";

function useGetHomePageImages() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchHomepageData();
        setData(response.data.homepageImageCollection.items[0]);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
}

export { useGetHomePageImages };

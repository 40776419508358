import styles from "./styles.module.css";
import { useGetHomePageImages } from "../../hooks/use-get-home-page-images";

function Home() {
  const { data, loading } = useGetHomePageImages();

  return (
    <div className={styles.container}>
      <img className={styles.big_home_caption} src={data?.bigImage?.url} />
      <img className={styles.small_home_caption} src={data?.smallImage.url} />
      {!loading ? (
        <a
          className={styles.instagram_link}
          href="https://www.instagram.com/louise_carbonnier/"
          target="_blank"
        >
          <span>&#10547;</span> Instagram
        </a>
      ) : null}
    </div>
  );
}

export default Home;

import { useGetContactText } from "../../hooks/use-get-contact-text";
import styles from "./styles.module.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const CREDIT_TEXT = ` Avec le soutien de la DRAC Centre-Val de Loire, du Conseil Régional du
Centre-Val de Loire, de la Communauté de Communes du Pays d'Issoudun, de
l'Etablissement Public de Coopération Culturelle d'Issoudun, et du Musée
de l'Hospice Saint-Roch d'Issoudun.`;

function Contact() {
  const { data, loading, error } = useGetContactText();
  console.log(data);
  return (
    <div className={styles.container}>
      <h2>Contact :</h2>
      <div className={styles.text_container}>
        {documentToReactComponents(data?.text.json)}
      </div>
      <p className={styles.credits}>{CREDIT_TEXT}</p>
    </div>
  );
}

export default Contact;

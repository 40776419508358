import { useState } from "react";
import styles from "./styles.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isBookMenuOpen, setIsBookMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <header className={styles.navbar}>
      <h1 onClick={() => navigate("/")}>Louise Carbonnier</h1>
      <button className={styles.hamburger} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "✕" : "☰"}
      </button>
      <nav className={isOpen ? styles.hamburger_menu : ""}>
        <Link
          to="/"
          onClick={() => {
            setIsOpen(false);
            setIsBookMenuOpen(false);
          }}
          className={isActive("/") ? styles.active : ""}
        >
          Accueil
        </Link>
        <Link
          to="/peintures"
          onClick={() => {
            setIsOpen(false);
            setIsBookMenuOpen(false);
          }}
          className={isActive("/peintures") ? styles.active : ""}
        >
          Peintures
        </Link>

        <div className={styles.book_navlink}>
          <p
            onClick={() => setIsBookMenuOpen(!isBookMenuOpen)}
            className={
              isActive("/livre-objet") ||
              isActive("/poesie") ||
              isBookMenuOpen === true
                ? styles.active
                : ""
            }
          >
            Livres
          </p>
          {isBookMenuOpen ? (
            <div className={styles.book_menu}>
              <Link
                to="/livre-objet"
                onClick={() => {
                  setIsOpen(false);
                  setIsBookMenuOpen(false);
                }}
                className={isActive("/livre-objet") ? styles.active : ""}
              >
                Livre-objet
              </Link>
              <Link
                to="/poesie"
                onClick={() => {
                  setIsOpen(false);
                  setIsBookMenuOpen(false);
                }}
                className={isActive("/poesie") ? styles.active : ""}
              >
                Poésie
              </Link>
            </div>
          ) : null}
        </div>

        <Link
          to="/textes"
          onClick={() => {
            setIsOpen(false);
            setIsBookMenuOpen(false);
          }}
          className={isActive("/textes") ? styles.active : ""}
        >
          Textes
        </Link>
        <Link
          to="/CV"
          onClick={() => {
            setIsOpen(false);
            setIsBookMenuOpen(false);
          }}
          className={isActive("/CV") ? styles.active : ""}
        >
          CV
        </Link>
        <Link
          to="/contact"
          onClick={() => {
            setIsOpen(false);
            setIsBookMenuOpen(false);
          }}
          className={isActive("/contact") ? styles.active : ""}
        >
          Contact
        </Link>
      </nav>
    </header>
  );
}

export default Navbar;

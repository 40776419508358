import { useGetPoetryBooks } from "../../hooks/use-get-poetry-books";
import Work from "../../components/work";

function PoetryBooks() {
  const { data, loading, error } = useGetPoetryBooks();

  if (loading || !data) {
    return <></>;
  }
  if (error) {
    return <div>Une erreur est survenue</div>;
  }

  return <Work data={data} linkLabel="Sélection de livres en pdf" />;
}

export default PoetryBooks;

const OBJECT_BOOKS_QUERY = `
{
  objectBooksCollection {
    items {
      title {
        json
      }
      image {
        url
      }
      pdf {
        url
      }
    }
  }
}`;

async function fetchObjectBooksData() {
  const response = await fetch(import.meta.env.VITE_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_APP_API_TOKEN}`,
    },
    body: JSON.stringify({ query: OBJECT_BOOKS_QUERY }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export { fetchObjectBooksData };

import React, { useState } from "react";
import styles from "./styles.module.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useGetCritics } from "../../hooks/use-get-critics";

function Critics() {
  const { data, loading } = useGetCritics();
  const [expandedId, setExpandedId] = useState(null);

  const toggleText = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const renderContent = (content, id) => {
    const title = content.content.find((item) => item.nodeType === "heading-3");
    const firstParagraph = content.content.find(
      (item) => item.nodeType === "paragraph",
    );

    const contentToDisplay = [title];
    if (firstParagraph) {
      contentToDisplay.push(firstParagraph);
    }

    if (expandedId === id) {
      return documentToReactComponents({
        nodeType: "document",
        content: content.content,
        data: {},
      });
    } else {
      return documentToReactComponents({
        nodeType: "document",
        content: contentToDisplay,
        data: {},
      });
    }
  };

  return (
    !loading && (
      <div className={styles.container}>
        {data.map((item, index) => (
          <div key={index} className={styles.text_container}>
            {renderContent(item.content.json, index)}
            <button onClick={() => toggleText(index)} className={styles.button}>
              {expandedId === index ? "Voir moins" : "Voir plus"}
            </button>
          </div>
        ))}
      </div>
    )
  );
}

export default Critics;

const CONTACT_QUERY = `
{
    contactCollection {
      items {
        text {
          json
        }
      }
    }
  }
`;

async function fetchContactData() {
  const response = await fetch(import.meta.env.VITE_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_APP_API_TOKEN}`,
    },
    body: JSON.stringify({ query: CONTACT_QUERY }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export { fetchContactData };

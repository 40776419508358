import { useGetObjectBooks } from "../../hooks/use-get-object-books";
import Work from "../../components/work";

function ObjectBooks() {
  const { data, loading, error } = useGetObjectBooks();

  if (loading || !data) {
    return <></>;
  }
  if (error) {
    return <div>Une erreur est survenue</div>;
  }

  return <Work data={data} linkLabel="Sélection de livres en pdf" />;
}

export default ObjectBooks;

const RESUME_QUERY = `
  {
    resumeItemsCollection(order: date_ASC) {
      items {
        title
        date
        category {
          title
        }
      }
    }
  }
  
`;

async function fetchResumeData() {
  const response = await fetch(import.meta.env.VITE_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_APP_API_TOKEN}`,
    },
    body: JSON.stringify({ query: RESUME_QUERY }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export { fetchResumeData };

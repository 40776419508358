const CRITICS_QUERY = `
{
    textCollection {
      items {
        content {
          json
        }
      }
    }
  }
`;

async function fetchCriticsData() {
  const response = await fetch(import.meta.env.VITE_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_APP_API_TOKEN}`,
    },
    body: JSON.stringify({ query: CRITICS_QUERY }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export { fetchCriticsData };

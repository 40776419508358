import styles from "./styles.module.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

function Work({ data, linkLabel }) {
  return (
    <div className={styles.container}>
      <img src={data?.image.url} alt="" />
      <div className={styles.right_panel}>
        <a className={styles.pdf_link} href={data?.pdf.url} target="_blank">
          <span>↓</span> [{linkLabel}]
        </a>

        <div className={styles.title}>
          {documentToReactComponents(data?.title.json)}
        </div>
      </div>
    </div>
  );
}

export default Work;

const HOMEPAGE_QUERY = `
{
  homepageImageCollection {
    items {
      bigImage {
        url
      }
      smallImage {
        url
      }
    }
  }
}

`;

async function fetchHomepageData() {
  const response = await fetch(import.meta.env.VITE_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_APP_API_TOKEN}`,
    },
    body: JSON.stringify({ query: HOMEPAGE_QUERY }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export { fetchHomepageData };
